import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppLoad } from 'src/app/app.load';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss']
})
export class ForgotUsernameComponent implements OnInit {

  modalRef: BsModalRef;
  constructor(private modalService: BsModalService,
    public appLoad:AppLoad) {
  }

  ngOnInit() {
  }
 
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,Object.assign({}, { class: 'forgotusername-modal-content' }));
    this.modalService.onHide.subscribe(res=>{
      document.body.style.paddingRight="0";
    });
    // this.modalRef.content.onClose.subscribe(result => {
    //   console.log('results', result);
    // });
  }

  CloseModel(){
    document.getElementById("close").click();
    //this.forgotPasswordForm.reset();
    this.modalRef.hide();
    //this.ClearFields();
  }
}
import { ForgotUsernameComponent } from './NonMember/forgot-username/forgot-username.component';
import { MemberFooterComponent } from './Common/themes/member-footer/member-footer.component';
import { FirstUpperRestLowerPipe, PhonePipe } from './Common/custom-pipes';
import { sanitizeHtmlPipe } from './Common/sanitizeHtml';
import { MatModule } from './mat.module';
import { HttpClientModule } from '@angular/common/http';
import { MainNavComponent } from './Common/themes/main-nav/main-nav.component';
import { HeaderComponent } from './Common/themes/header/header.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import { DatePipe, CurrencyPipe, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggingService } from './common/logging.service';
import { FooterComponent } from './Common/themes/footer/footer.component';
import { CustomPopupComponent } from './Common/themes/custom-popup/custom-popup.component';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MemberFooterComponent,
    MainNavComponent,
    CustomPopupComponent,
    ForgotUsernameComponent,
    sanitizeHtmlPipe,
    PhonePipe,
    FirstUpperRestLowerPipe
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatModule,
    sanitizeHtmlPipe,
    PhonePipe,
    FirstUpperRestLowerPipe,
    HeaderComponent,
    CustomPopupComponent,
    ForgotUsernameComponent,
    FooterComponent,
    MemberFooterComponent,
    MainNavComponent,
    NgxCaptchaModule,
  ],
  providers: [
    DatePipe, 
    LoggingService,
    CurrencyPipe, 
    { provide: LOCALE_ID, useValue: 'en-EN' }
  ],
  entryComponents: [
  ]
})
export class CommenModule { }  

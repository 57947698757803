import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const lazyRoutes: Routes = [
  { path:"en/nonmember",loadChildren: () => import('./NonMember/nonmember.module').then(m => m.NonMemberModule) },
  { path:"fr/nonmember",loadChildren: () => import('./NonMember/nonmember.module').then(m => m.NonMemberModule) },
  { path:"en/member",loadChildren: () => import('./member/member.module').then(m => m.MemberModule) },
  { path:"fr/member",loadChildren: () => import('./member/member.module').then(m => m.MemberModule) },
  { path:"",redirectTo:"en/nonmember",pathMatch:"full" },
  { path:"**",redirectTo:"en/nonmember" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(lazyRoutes, { useHash: false, scrollPositionRestoration: "top",onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { AppLoad } from 'src/app/app.load';
import { HttpHeadersInterceptor } from './Common/interceptHeaders';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommenModule } from './common.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';
import { Idle, DEFAULT_INTERRUPTSOURCES, NgIdleModule } from '@ng-idle/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import {CustomPopupComponent} from './Common/themes/custom-popup/custom-popup.component'

import { GenCanDeactivateGuard } from './Common/CanDeactivateGuard';
import { Title } from '@angular/platform-browser';




export function getApplicationData(appLoadService: AppLoad) {
  return () => appLoadService.GetLanguageResource();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommenModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    NgIdleModule.forRoot(),
     ModalModule.forRoot(),
  
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: getApplicationData, deps: [AppLoad], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
    Idle,
    //CanDeactivateGuard,
    GenCanDeactivateGuard,
    Title
  ],
 
  bootstrap: [AppComponent]
})
export class AppModule { }

import { AppLoad } from 'src/app/app.load';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SpinnerService } from './Common/spinner.service';
import { Idle } from '@ng-idle/core';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public language: string = "English";

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  childModal: any;

  constructor(private router: Router,
    public spinner: SpinnerService,
    public appLoad: AppLoad,
    private cdr: ChangeDetectorRef,) {
      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
          gtag('config', 'UA-185499118-33', 
                  {
                    'page_path': event.urlAfterRedirects
                  }
                 );
         }
      });
  }
  
  ngOnInit(): void {
    this.appLoad.Timer();
    this.appLoad.StartTimer();
  }

  ChangeLanguage() {
    let urlSegment: any[] = this.router.url.split("/");
    let urlLang: string = "en";
    if (urlSegment[1] == "en")
      urlLang = "fr";
    urlSegment[1] = urlLang;
    this.router.navigateByUrl(urlSegment.join('/'));
    this.cdr.detectChanges();
  }

  OnMemberPage() {
    var memberpage = false;
    if (this.appLoad.OnMemberPage() && window.innerWidth <= 768) {
      memberpage = true;
    }
    return memberpage;
  }
}

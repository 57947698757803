import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

// @Injectable()
// export class CanDeactivateGuard implements CanDeactivate<CreditcardComponent> {

//   constructor(private modalService: BsModalService) {}
  
//   canDeactivate(component: CreditcardComponent,
//                 _route: ActivatedRouteSnapshot,
//                 _state: RouterStateSnapshot) {
//       if (component.displayIframeForEdit|| (component.creditCardForm.dirty  )) {
//         const subject = new Subject<boolean>();
//         let config = {
//           backdrop: true,
//           ignoreBackdropClick: true,
//           class: 'custom-modal-center'
//         };
//         const modal = this.modalService.show(CustomPopupComponent, config);
//         modal.content.subject = subject;
//         return subject.asObservable();
//       }
//       return true;
//     }
// }

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class GenCanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }

}
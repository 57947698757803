import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppLoad } from '../../app/app.load';
import { ErrorService } from '../Common/errorService';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private _http: HttpClient,
    private appLoad: AppLoad,
    private errorService: ErrorService ) {
  }

  AddLog(logLevel:any,details:any){
    this.AddLogs(logLevel,details).subscribe(res=>{
      },error=>{
      })
    }

AddLogs(logLevel:any,details:any): Observable<any>{
    return this._http.post(this.appLoad.appSettings.commonSettings.CfmhApiUrl+"account/AddLogs?logLevel="+logLevel+"&logDetails="+details+"",null)
    .pipe(
      catchError(this.errorService.handleError)
    );
}



  }


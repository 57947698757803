import { Component, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AppLoad } from '../../../app.load';
import { Router, ActivatedRoute } from '@angular/router';
import {GoogleAnalyticsService} from './../../../Common/GoogleAnalytics.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements AfterViewInit{

  activePageTitle: string = "";
  pageId: number = 0;
  mobileActivePageTitle: string = "";
  

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              public appLoad: AppLoad,
              private cdr: ChangeDetectorRef,
              private googleAnalyticsService:GoogleAnalyticsService) {
   
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.SetPageTitle();
    this.cdr.detectChanges();
  }

  CloseModel(){
    document.getElementById("close").click();
  }

  SetPageTitle(){
    this.activatedRoute.url.subscribe(() => {
      if (this.activatedRoute.snapshot.firstChild.data != null && this.activatedRoute.snapshot.firstChild.data.CMSKey) {
        let pageTitleKey = this.activatedRoute.snapshot.firstChild.data.CMSKey;
        let mobilePageTitleKey = this.activatedRoute.snapshot.firstChild.data.MobileCMSKey;
        let pageId = this.activatedRoute.snapshot.firstChild.data.pageId;
        this.activePageTitle= this.appLoad.currentLanguageResources[pageTitleKey];
        this.mobileActivePageTitle= mobilePageTitleKey==null? this.appLoad.currentLanguageResources[pageTitleKey]:this.appLoad.currentLanguageResources[mobilePageTitleKey];
        this.pageId = pageId; 
      }
    });
  }

  openModel(){
    document.getElementById("need").click();
  }
  
  openNav() {
    document.getElementById("mainSidenav").style.width = "250px";
    document.getElementById("mainSidenav").style.paddingLeft = "35px";
    document.getElementById("mobile-nav-overly").style.display="block";
  }
  
  
  closeNav() {
    if(window.innerWidth<=768){
      document.getElementById("mainSidenav").style.width = "0";
      document.getElementById("mainSidenav").style.paddingLeft = "0";
      document.getElementById("mobile-nav-overly").style.display="none";
    }
  }
  
  ChangeLanguage(){
    let urlSegment:any[] = this.router.url.split("/");
    let urlLang:string="en";
    if(urlSegment[1]=="en")
      urlLang = "fr";
    urlSegment[1]=urlLang;
    this.router.navigateByUrl(urlSegment.join('/'));
  }
  
  Logout(){
    this.googleAnalyticsService.eventEmitter("Submit", "engagement", "Logout", 1);
    localStorage.removeItem(this.appLoad.appSession);
    localStorage.removeItem("timedOut");
    this.appLoad.NavigateToUrl("nonmember/home")   
  }

}
<div class="container-fluid tilte-container group">
  <div class="mobile-view group">
    <div class="smalldevice-img-logo" (click)="appLoad.NavigateToUrl('member/dashboard');">
      <img [src]="appLoad.currentLanguageResources.cardAssist_nonMemberHeader_logo" alt="" style="height: 40px;align-self: center;">
    </div>
    <div class="show-title col-7 text-truncate">
      <p class="page-title-font">
      {{activePageTitle}}
      </p>
    </div>
    <div class="need-assistance">
      <span tabindex="0" (keyup.enter)="openModel()" id="need" style="font-size:30px;cursor:pointer;color: #333333;font-size: 1.125rem;"  data-toggle="modal" data-target="#needAssistance">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_needAssistance}}</span>
    </div>
    <div class="d-lg-none" style="float: right;padding: 20px 0;padding-right: 4px;">
      <span class="mainsider-nav-opener" (click)="openNav()">&#9776;</span>
    </div>
  </div>
  <div class="mobile-show-title group">
    <div class="w-100" style="align-self: center;">
      <p class="page-title-font"> {{mobileActivePageTitle}} </p>
    </div>
  </div>
</div>

<div id="mobile-nav-overly" (click)="closeNav()"></div>
<div class="main-sidenav" id="mainSidenav">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
  <div class="img-logo" tabindex="0" (keyup.enter)="appLoad.NavigateToUrl('member/dashboard');" (click)="appLoad.NavigateToUrl('member/dashboard');">
    <img [src]="appLoad.currentLanguageResources.cardAssist_nonMemberHeader_logo" alt="" style="height: 2.5rem;align-self: center;">
  </div>
  <div class="sidenav-listitems">
    <div class="d-flex">
      <div [class]="pageId == 1?'circle-active':'circle'"
           [style]="{'width':appLoad.selectedLanguage == 'fr'?'0.813rem':'0.625rem'}"></div><a [class]="pageId == 1?'active-link':'inactive-link'" href="javascript:void(0)" (click)="appLoad.NavigateToUrl('member/dashboard');closeNav()">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_infoProtection}}</a>
    </div>
    <div class="d-flex" (click)="closeNav()">
      <div [class]="pageId == 3?'circle-active':'circle'"
           [style]="{'width':appLoad.selectedLanguage == 'fr'?'1rem':'0.625rem'}"></div><a [class]="pageId == 3?'active-link':'inactive-link'" href="javascript:void(0)" (click)="appLoad.NavigateToUrl('member/lostwallet');closeNav()">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_lostWalletProtect}}</a>
    </div>
    <div class="d-flex" (click)="closeNav()">
      <div [class]="pageId == 4?'circle-active':'circle'"></div><a [class]="pageId == 4?'active-link':'inactive-link'" href="javascript:void(0)" (click)="appLoad.NavigateToUrl('member/travelprotection');closeNav()">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_travelProtection}}</a>
    </div>
    <div class="d-flex" (click)="closeNav()">
      <div [class]="pageId == 2?'circle-active':'circle'"></div><a [class]="pageId == 2?'active-link':'inactive-link'" href="javascript:void(0)" (click)="appLoad.NavigateToUrl('member/profile');closeNav()">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_profile}}</a>
    </div>
    <div class="mobile-need-assistance" (click)="closeNav()">
      <div [class]="pageId == 4?'circle-active':'circle'"></div><a [class]="pageId == 6?'active-link':'inactive-link'"  data-toggle="modal" data-target="#needAssistance" href="javascript:void(0)">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_needAssistance}}</a>
    </div>
    <div class="mobile-need-assistance" (click)="closeNav()">
      <div [class]="pageId == 5?'circle-active':'circle'"></div><a [class]="pageId == 7?'active-link':'inactive-link'" href="javascript:void(0)" (click)="appLoad.NavigateToUrl('member/contactus')">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_contact}}</a>
    </div>
    <div class="mobile-need-assistance" (click)="ChangeLanguage();closeNav()">
      <div [class]="pageId == 6?'circle-active':'circle'"></div><a [class]="pageId == 8?'active-link':'inactive-link'" href="javascript:void(0)">{{appLoad.language}}</a>
    </div>
  </div>
  <div style="width: 250px;height: 4.6875rem;background: #333333 0% 0% no-repeat padding-box;display: flex;position: absolute;left: 0;bottom: 5rem;cursor: pointer;" tabindex="0" (keyup.enter)="Logout()" (click)="Logout()">
    <a style="align-self: center;margin: auto;font-size: 1rem;color: #FFFFFF;text-decoration: none;">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_logout}}</a>
  </div>
</div>

<div class="modal fade" id="needAssistance"  data-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog need-assistance-popup">
    <div class="modal-content" style="border-radius: 0;">
      <div class="modal-body">
        <span  style="display: block;position: absolute; right: 0.313rem;top: -0.563rem;cursor: pointer;font-size:1.875rem;color: #757575;" id="close" data-dismiss="modal" aria-label="Close Icon" tabindex="0" alt="Close Icon" (keyup.enter)="CloseModel()">&times;</span>
        <b style="font-family: Prompt;font-size: 1.125rem;">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_needAssistTitle}}</b>
        <p style="margin-bottom: 25px;margin-top: 0.625rem;font-family: cabin;font-size:1rem;color: #555555;">{{appLoad.currentLanguageResources.cardAssist_memberNavBar_needAssistText}}</p>
        <div class="group">
          <a tabindex="0" class="need-assist-btn" href="tel:1-800-456-4042" style="margin-bottom:1.25rem;">
            <span>{{appLoad.currentLanguageResources.cardAssist_memberNavBar_needAssistBtn1}}</span>
          </a>
          <a tabindex="0" class="need-assist-btn" href="tel:1-416-734-3142">
          <span>{{appLoad.currentLanguageResources.cardAssist_memberNavBar_needAssistBtn2}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
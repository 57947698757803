<router-outlet></router-outlet>
<!-- <app-footer></app-footer> -->
<ngx-loading [show]="spinner.isVisible() | async" [config]="{ fullScreenBackdrop:true }"></ngx-loading>

<button type="button" title="sessionTimeOutbtn" id="sessionTimeOut"  style ="display: none;height: 40px;width: 60px;margin-left: 350px;" data-toggle="modal" data-target="#sessionTimeOutModel">
</button>

<div class="modal fade" id="sessionTimeOutModel" tabindex="-1" role="dialog"  data-backdrop="static" 
  aria-hidden="true">
  <div class="modal-dialog custom-idelmodal-center" role="document" >
    <div class="modal-content" style="border-radius:0px;">
      <div class="modal-body" style="padding: 30px;">
        <div>
          <p style="text-align: center;font-family: Cabin;font-weight: bold;font-size: 1.25rem;color: #000000;">{{appLoad.currentLanguageResources.cardAssist_sessionTimeOut_header}}</p>
          <p style="font-family:Cabin;font-size: 1.125rem;color: #000000;margin: 0;" >{{appLoad.currentLanguageResources.cardAssist_sessionTimeOut_body}}</p>
          <p style="text-align: center;font-family: Cabin;font-weight: bold;font-size: 1.375rem;color: #000000;">{{appLoad.idleState}}</p>
        </div>
        <div class="group button-group d-none">
          <button tabindex="0" data-dismiss="modal" aria-label="Close" id="close-modal-btn" class=" btn btn-default leaveSessiontBtn">{{appLoad.currentLanguageResources.cardAssist_sessionTimeOut_logoutBtn}}</button>
          <button tabindex="0" data-dismiss="modal" aria-label="Close" id="close-modal-btn" class=" btn btn-default continueBtn">{{appLoad.currentLanguageResources.cardAssist_sessionTimeOut_contBtn}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
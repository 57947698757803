<div class="mx-auto  member-footer">
  <div class="text-center" style="padding-bottom: 10px;">
    <a style="cursor: pointer;"  tabindex="0" (keyup.enter)="OpenPrivacyPolicy()" (click)="OpenPrivacyPolicy()">{{appLoad.currentLanguageResources.cardAssist_footer_privacyPolLink}}</a> &nbsp;|&nbsp;
    <a style="cursor: pointer;" tabindex="0" (keyup.enter)="TnC()" (click)="TnC()">{{appLoad.currentLanguageResources.cardAssist_footer_tAndCLink}}</a> &nbsp;|&nbsp;
    <a style="cursor: pointer;" tabindex="0" (keyup.enter)="OpenFaq()" (click)="OpenFaq()">{{appLoad.currentLanguageResources.cardAssist_footer_faqLink}}</a>
    <a></a>
  </div>
  <div class="text-center text" [innerHtml]="appLoad.currentLanguageResources.cardAssist_footer_text">
  </div>
</div>

<a tabindex="0" style="text-decoration: underline;font-size: 0.75rem;font-family: Poppins;color: #006FE6;;cursor: pointer;"
   (click)="openModal(template)" (keyup.enter)="openModal(template)">
  {{appLoad.currentLanguageResources.cardAssist_login_Link2}}
</a>

<!-- Forgot Username Modal -->
<ng-template #template>
  <div class="modal-header custom-modal-header" style="border-radius: unset;"> 
    <div></div>
    <p style="font-size: 1.25rem;align-items: center;font-family: Poppins;color: #FFFFFF;">{{appLoad.currentLanguageResources.cardAssist_forgotUsername_header}}</p>
    <div style="position: relative;">
      <span class="pull-right" style="cursor:pointer;" id="close" data-dismiss="modal" (click)="CloseModel()"   aria-label="Close" 
      style="margin-top: -5px;cursor: pointer;" >
      <img style="height: 0.9375rem;width: 0.9375rem"  tabindex="0" (keyup.enter)="CloseModel()"  alt="Close Icon" src="../../../assets/appIcon/x-icon.svg" />
    </span>
    </div>
  </div>
  <div class="modal-body custom-modal-body">
    <p>{{appLoad.currentLanguageResources.cardAssist_forgotUsername_bodyText}}</p>
    <a [href]="appLoad.currentLanguageResources.cardAssist_forgotUsername_link">{{appLoad.currentLanguageResources.cardAssist_forgotUsername_linkText}}</a>
  </div>
</ng-template>
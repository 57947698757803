<header id="header">
  <div class="header-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-sm-6 col-4 header-top-left no-padding">
        </div>
        <div class="col-lg-6 col-sm-6 col-8 header-top-right no-padding">
          <a style="cursor: pointer;font-size: 1rem;" tabindex="0" (keyup.enter)="appLoad.NavigateToUrl('nonmember/contactus')" (click)="appLoad.NavigateToUrl('nonmember/contactus')" >{{appLoad.currentLanguageResources.cardAssist_nonMemberHeader_contactUs}}</a>
          <a style="cursor: pointer;font-size: 1rem;" tabindex="0" (keyup.enter)="ChangeLanguage()" (click)="ChangeLanguage()">{{appLoad.language}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="container main-menu">
    <div class="row align-items-center justify-content-between d-flex">
      <div id="logo">
        <a tabindex="0" (keyup.enter)="appLoad.NavigateToUrl('nonmember/home')" (click)="appLoad.NavigateToUrl('nonmember/home')" style="cursor: pointer;">
          <img [src]="appLoad.currentLanguageResources.cardAssist_nonMemberHeader_logo" alt="CardAssistLogo" title="" height="40px"/>
        </a>
      </div>

   
       <div class="hamburger" tabindex="0" (keyup.enter)="openNav()"  (click)="openNav()">
        &#9776;
      </div>
      <nav id="nav-menu-container">
        <a href="javascript:void(0)" class="closebtn" style="text-decoration: none;font-size: 28px; padding-right: 20px;color: black;
        float: right;position: relative;z-index: 1;" (click)="closeNav()" >&times;</a>
        <ul class="nav-menu">
          <li class="menu-active"  tabindex="0" (keyup.enter)="appLoad.NavigateToUrl('nonmember/home');closeNav()"><a   (click)="appLoad.NavigateToUrl('nonmember/home');closeNav()" [class]="pageId == 1?'active-header':''" style="cursor: pointer;">{{appLoad.currentLanguageResources.cardAssist_nonMemberHeader_home}}</a></li>
          <li  tabindex="0" (keyup.enter)="appLoad.NavigateToUrl('nonmember/infoProtection');closeNav()"><a (click)="appLoad.NavigateToUrl('nonmember/infoProtection');closeNav()" [class]="pageId == 2?'active-header':''" style="cursor: pointer;">{{appLoad.currentLanguageResources.cardAssist_nonMemberHeader_infoProt}}</a></li>
          <li  tabindex="0" (keyup.enter)="appLoad.NavigateToUrl('nonmember/lostwallet');closeNav()"><a  (click)="appLoad.NavigateToUrl('nonmember/lostwallet');closeNav()" [class]="pageId == 3?'active-header':''" style="cursor: pointer;">{{appLoad.currentLanguageResources.cardAssist_nonMemberHeader_LostWallet}}</a></li>
          <li  tabindex="0" (keyup.enter)="appLoad.NavigateToUrl('nonmember/travelprotection');closeNav()"><a  (click)="appLoad.NavigateToUrl('nonmember/travelprotection');closeNav()" [class]="pageId == 4?'active-header':''" style="cursor: pointer;">{{appLoad.currentLanguageResources.cardAssist_nonMemberHeader_travelProt}}</a></li>
          <li class="mobile-link"><a (click)="appLoad.NavigateToUrl('nonmember/contactus');closeNav()" style="cursor: pointer;">{{appLoad.currentLanguageResources.cardAssist_nonMemberHeader_contactUs}}</a></li>
          <li class="mobile-link"><a tabindex="0" (keyup.enter)="ChangeLanguage();closeNav()" style="cursor: pointer;" (click)="ChangeLanguage();closeNav()">{{appLoad.language}}</a></li>
        </ul>
      </nav>
      <div id="mobile-body-overly" (click)="closeNav()"></div> 



      
    </div>
  </div>
</header>

import { AppLoad } from './../app.load';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorService implements ErrorHandler {
    error: any = {};
    _router: any;
    constructor(private injector: Injector, private appSettings: AppLoad) {
    }

    handleError(error) {
        // this.error=error; 
        console.log(this.error);
        let errorMessage: string = "";
        if (error.error.Errors != null) {
            error.error.Errors.forEach(element => {
                errorMessage += element.Message + "<br/>";
            });
        }
        else if (error.error.message != null) {
            errorMessage = error.error.message + " " + error.error.messageDetail;
        }
        else {
            errorMessage = error.error.Message + " " + error.statusText
        }

        return throwError(errorMessage);
    }
}
import { Component, OnInit, Input, Inject, AfterViewInit } from '@angular/core';
import { AppLoad } from 'src/app/app.load';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit,AfterViewInit {

  @Input() showBenefitGud:boolean=false;
  public IsPaddingLeftAvailable:boolean = false;

  constructor(public appLoad: AppLoad,
    private router:Router) {
    }

  ngOnInit() {
    this.IsPaddingLeftAvailable = this.appLoad.OnMemberPage() && window.innerWidth>540?true:false;
  }

  ngAfterViewInit(): void {
    this.OnMemberPage();
  }

  OpenPrivacyPolicy(){
    if(this.appLoad.OnMemberPage()){
      this.appLoad.NavigateToUrl("member/privacyPolicy");
    }
    else{
      this.appLoad.NavigateToUrl("nonmember/privacyPolicy");
    }
  }

  OnMemberPage(){
    var memberpage=false;
    if(this.appLoad.OnMemberPage() && window.innerWidth>768){
      memberpage=true;
    }
    return memberpage;
  }

  OpenFaq(){
    if(this.appLoad.OnMemberPage()){
      this.appLoad.NavigateToUrl("member/faq");
    }
    else{
      this.appLoad.NavigateToUrl("nonmember/faq");
    }
  }

  TnC() {
    let url;
    if(this.appLoad.OnMemberPage())
      url='member/termsandcondition'
    else
      url='nonmember/termsandcondition'
    this.appLoad.NavigateToUrl(url);
  }
}

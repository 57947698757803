import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhonePipe implements PipeTransform {
  transform(rawNum:string) {
    return rawNum.substring(0,3)+"-"+rawNum.substring(3,6)+"-"+rawNum.substring(6);
  }
}

@Pipe({
  name: "firstUpperRestLower"
})
export class FirstUpperRestLowerPipe implements PipeTransform {
  transform(rawStr:string) {
    let array = rawStr.split(" ");
    rawStr = "";
    for (let index = 0; index < array.length; index++) {
      let firstLatter = array[index].substring(0,1).toUpperCase();
      let otherLatter = array[index].substr(1,array[index].length).toLowerCase();
      rawStr += firstLatter+otherLatter+" ";
    }
    return rawStr;
  }
}

import { AppLoad } from 'src/app/app.load';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../../spinner.service';

declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  pageId = 1;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public spinner: SpinnerService,
    public appLoad: AppLoad,
    private cdr: ChangeDetectorRef) {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
      $(window).scroll(() => {
        if ($(this).scrollTop() > 100) {
          $('#header').addClass('header-scrolled');
        } else {
          $('#header').removeClass('header-scrolled');
        }
      });
      this.SetPageTitle();
      this.cdr.detectChanges();
  }

  
  SetPageTitle(){
    this.activatedRoute.url.subscribe(() => {
      if (this.activatedRoute.snapshot.firstChild.data != null && this.activatedRoute.snapshot.firstChild.data.pageId) {
        let pageId = this.activatedRoute.snapshot.firstChild.data.pageId;
        this.pageId = pageId; 
      }
    });
  }


  openNav() {
    document.getElementById("mobile-body-overly").style.display = "block";
    document.getElementById("nav-menu-container").style.right = "0";
  }

  closeNav() {
    if(window.innerWidth<=768){
      document.getElementById("mobile-body-overly").style.display = "none";
      document.getElementById("nav-menu-container").style.right = "-260px";
    }
  }

  ChangeLanguage() {
    let urlSegment: any[] = this.router.url.split("/");
    let urlLang: string = "en";
    if (urlSegment[1] == "en")
      urlLang = "fr";
    urlSegment[1] = urlLang;
    this.router.navigateByUrl(urlSegment.join('/'));
    this.cdr.detectChanges();
  }

}

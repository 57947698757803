import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppLoad } from 'src/app/app.load';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss']
})
export class CustomPopupComponent implements OnInit {

  subject: Subject<boolean>;
  btn1Label: string = this.appLoad.currentLanguageResources.cardAssist_pageLeaveAlert_btn1;
  btn2Label: string = this.appLoad.currentLanguageResources.cardAssist_pageLeaveAlert_btn2;
  text: string = this.appLoad.currentLanguageResources.cardAssist_pageLeaveAlert_text;

  constructor(public bsModalRef: BsModalRef,private appLoad:AppLoad) { 
  }

  ngOnInit() {
  }

  action(value: boolean) {
    this.bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }

}

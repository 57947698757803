import { SpinnerService } from './spinner.service';

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppLoad } from '../app.load';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {

    constructor(public appGlobal: AppLoad,
                public spinnerService:SpinnerService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.show();
        let token: "";
        let userSession = this.appGlobal.GetSession(this.appGlobal.appSession);
            if(userSession!=null)
                token = userSession.loginDetails.access_token;
        if (!(request.url.indexOf('/home/GetLanguageResources') > -1) && !(request.url.indexOf("account/uploadFile")>-1)) {
            request = request.clone({
                setHeaders: {
                    "Content-Type": "application/json",
                    "Accept-Language": this.appGlobal.selectedLanguage,
                    "SiteID": this.appGlobal.appSettings.commonSettings.siteId1,
                    "SiteUrl": this.appGlobal.appSettings.commonSettings.siteUrl,
                    'Authorization': 'Bearer ' + token,
                    'pragma': 'no-cache'
                }
            })
        }
        return next.handle(request)
        .pipe(
            tap(event=>{
                if (event instanceof HttpResponse) {     
                    this.spinnerService.hide();
                  }
                }, error => {
                  this.spinnerService.hide();
            })
        )
    }
}

<footer class="footer-area section-gap">
  <div class="container">
    <div class="row">
      <div class="col-lg-2">
      </div>
      <div class="col-lg-8">
        <div class="text-center">
          <p>
            <a style="cursor: pointer;" tabindex="0" (keyup.enter)="OpenPrivacyPolicy()" (click)="OpenPrivacyPolicy()" class="mr-30 text-white">{{appLoad.currentLanguageResources.cardAssist_footer_privacyPolLink}}</a>
            <a style="cursor: pointer;" tabindex="0" (keyup.enter)="TnC()"  (click)="TnC()" class="mr-30 text-white">{{appLoad.currentLanguageResources.cardAssist_footer_tAndCLink}}</a>
            <a style="cursor: pointer;"  tabindex="0" (keyup.enter)="OpenFaq()"  (click)="OpenFaq()" class="text-white">{{appLoad.currentLanguageResources.cardAssist_footer_faqLink}}</a>
          </p>
          <p [innerHtml]="appLoad.currentLanguageResources.cardAssist_footer_text"></p>
        </div>
      </div>
      <div class="col-lg-2">
      </div>
    </div>
  </div>
</footer>
import { HttpClient } from '@angular/common/http';
import { HostListener, Injectable, Injector, NgZone, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavigationStart } from "@angular/router";
import { Event as NavigationEvent } from "@angular/router";
import { MatDialog } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { timer } from 'rxjs/internal/observable/timer';
import { Subject } from 'rxjs';
import { GoogleAnalyticsService } from './Common/GoogleAnalytics.service';


declare var require: any;

@Injectable({
  providedIn:"root"
})
export class AppLoad {
 
  _router: any;
  public appSettings: any = {};
  public selectedLanguage: any = "en"
  public selectedLanguageText: any ="";
  public currentLanguageResources: any;
  public allLanguageResources: any = {};
  public isHomePage = false;
  public sessionWarningTimer$ = new Subject();
  public profileSession: any;
  public documentListSession: any;
  public reimbursmentSession: any;
  public loginSession: any;
  public provinceSession: any;
  public contactSession: any;
  session: typeof session = session;
  public version:any;
  public a:any;
  public isMainPage:any;
  public changeColorMenuLbl: boolean;
  public changeColorHomeAssistLbl: boolean;
  public changeColorAutoAssistLbl: boolean;
  public changeColorHealthAssistLbl: boolean;
  public changeColorMenuRegLbl: boolean;
  public changeColorMenuDataBkpLbl: boolean;
  public changeColorMenuReimLbl: boolean;
  public changeColorMenuProfileLbl: boolean;
  public changeColorMenuContactLbl:boolean;
  public changeColorMenuLanguageLbl:boolean;
  public customizedListSession:string;
  public securityToken: string;
  public sessionTimeOut: boolean = false;
  public previceUrl: string="";
  public Os:string="";
  public profileImage:any=null;
  public activateLinkClicked=false;
  public showHeaderFooter:any=true;
  public langToggle:any=false;
  public appSession: any="CardAssist__loginSession";
  public language: string="";
  idleState: any;
  timedOut = false;
  showTostMsg:string = null;
  
 

  constructor(private injector: Injector,
              private ngZone:NgZone,
              public dialog: MatDialog,
              private titleService: Title,
              private http:HttpClient,
              private idle: Idle,
              private googleAnalyticsService:GoogleAnalyticsService) {
  }

  async GetLanguageResource(){
    this.appSettings.commonSettings = await require("../assets/appsettings.json");
    this.appSettings.emailConfig = require("../assets/emailConfig.json");
    let hostname = window.location.hostname;
    if(this.appSettings.commonSettings.enRedirectUrl.indexOf(hostname)>-1){
      window.location.href=this.appSettings.commonSettings.enHostURL;
    }
    else if(this.appSettings.commonSettings.frRedirectUrl.indexOf(hostname)>-1){
      window.location.href=this.appSettings.commonSettings.frHostURL;
    }
    await this.http.get(this.appSettings.commonSettings.CmsApiUrl + "home/GetLanguageResources?siteId=" + this.appSettings.commonSettings.siteId).
        toPromise()
        .then((data:any) => {
          this.appSettings.applicationData = data;
          localStorage.setItem("languagedatastorage", JSON.stringify(this.appSettings.applicationData))
          this.PrepareLanguageResources(this.appSettings.applicationData);
        }, error => {
        });
  }

  PrepareLanguageResources(appDetails) {
    this.allLanguageResources.englishResources = {};
    this.allLanguageResources.frenchResources = {};
    appDetails.forEach(element => {
      if (element.language == "en")
        this.allLanguageResources.englishResources[element.key] = element.value;
      else
        this.allLanguageResources.frenchResources[element.key] = element.value;
    });
    let router = this.injector.get(Router);
    router.events.pipe(filter((event: NavigationEvent) => {
      return (event instanceof NavigationStart);
    })).subscribe((event: NavigationStart) => {
     let  routes  =  event.url.split('/');
     if(routes[1]=="fr"){
        this.currentLanguageResources = this.allLanguageResources.frenchResources;
        this.selectedLanguage ="fr";
        this.language = "English";
        this.selectedLanguageText=this.currentLanguageResources.haplus_public_home_lang_label;
        localStorage.setItem("currentLanguageResorces", JSON.stringify(this.currentLanguageResources));
     }
     else if(routes[1]=="en"){
      this.currentLanguageResources = this.allLanguageResources.englishResources;
      this.selectedLanguage = "en";
      this.language = "Français";
      this.selectedLanguageText=this.currentLanguageResources.haplus_public_home_lang_label;
      localStorage.setItem("currentLanguageResorces", JSON.stringify(this.currentLanguageResources));
    }
     else if(this.appSettings.commonSettings.frHostURL.indexOf(window.location.hostname)>-1) {
       this.currentLanguageResources = this.allLanguageResources.frenchResources;
       this.selectedLanguage = "fr";
       this.selectedLanguageText = this.currentLanguageResources.ip360_header_languagelinktext;
       let router = this.injector.get(Router);
       router.navigateByUrl("fr/nonmember/home");
     }
     else if(this.appSettings.commonSettings.enHostURL.indexOf(window.location.hostname)>-1) {
       this.currentLanguageResources = this.allLanguageResources.englishResources;
       this.selectedLanguage = "en";
       this.selectedLanguageText = this.currentLanguageResources.ip360_header_languagelinktext;
       let router = this.injector.get(Router);
       router.navigateByUrl("en/nonmember/home");
     }
     else{
      this.currentLanguageResources = this.allLanguageResources.englishResources;
      this.selectedLanguage = "en";
      this.language = "Français";
      this.selectedLanguageText=this.currentLanguageResources.haplus_public_home_lang_label;
      localStorage.setItem("currentLanguageResorces", JSON.stringify(this.currentLanguageResources));
     }
     this.titleService.setTitle(this.currentLanguageResources.haplus_page_title_index_title);
    });

    if(this.currentLanguageResources==null){
      this.currentLanguageResources = this.allLanguageResources.englishResources;
      this.selectedLanguage ="en"; //"en";
      this.language = "Français";
      this.selectedLanguageText=this.currentLanguageResources.haplus_public_home_lang_label;
      localStorage.setItem("currentLanguageResorces", JSON.stringify(this.currentLanguageResources));
    }
     this.Os=getMobileOperatingSystem();
  }

  Logger(api:string,body:string){
  if(this.appSettings.commonSettings.enableLog){
    console.log("language selected is "+ this.selectedLanguage);
    console.log("API called"+api);
    console.log("request body is "+body);
    }
  } 

  LogInfo(message:any){
  let data = JSON.stringify(message);
  if(this.appSettings.commonSettings.enableLog){
      console.log("Info: "+data);
    }
  }
  
  NavigateToUrl(moduleName: string) {
    let router = this.injector.get(Router);
    let routes = router.url.split('/');
    let language = "en"
    if (routes[1] == "fr")
      language = "fr";
    let url = ['/' + language + '/' + moduleName + ''];
    this.googleAnalyticsService.eventEmitter("PageView", "engagement", moduleName, 1);
    router.navigate(url);
  }

  NavigateToUrlWithReload(moduleName: string) {
    let router = this.injector.get(Router);
    let routes = router.url.split('/');
    let language = "en"
    if (routes[1] == "fr")
      language = "fr";
    let url = ['/' + language + '/' + moduleName + ''];

    this.ngZone.run(()=>{
      router.navigate(url)
      .then(() => {
    window.location.reload();
    });
    });
    //router.navigate(url).then;
  }

  NavigateToUrlwithData(moduleName: string,data:any) {
    let router = this.injector.get(Router);
    let routes = router.url.split('/');
    let language = "en"
    if (routes[1] == "fr")
      language = "fr";
    let url = ['/' + language + '/' + moduleName + ''];
    
    router.navigate(url,data);
  }

 //Set Main Session
  SetSession(data: any) {
    localStorage.setItem(this.appSession, JSON.stringify(data));
  } 

  GetSession(sessionName: string): any {
    let SessionJson=null
    let SessionObj = localStorage.getItem(sessionName);
      SessionJson = JSON.parse(SessionObj);
    return SessionJson;
  }

  OnMemberPage(){
    var memberpage=false;
    let router = this.injector.get(Router);
    let  routes  =  router.url.split('/');
    if( routes != null && routes.indexOf("member")  >  -1 ){
      memberpage=true;
    }
    return memberpage;
  }

  Activate() {
    this.activateLinkClicked = true;
    //this.NavigateToUrl('home');
  }

  Timer()
  {
    this.idle.setIdle(1);
    this.idle.setTimeout(this.appSettings.commonSettings.sessionTimeOut);
     this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
     this.idle.onIdleEnd.subscribe((count) =>{
      this.reset();
      document.getElementById("close-modal-btn").click();
     });
  
    this.idle.onIdleStart.subscribe((time) => {
      // this.idleState = 'You\'ve gone idle!';
    })
    this. idle.onTimeoutWarning.subscribe((countdown) => {
      
    this.idleState = Math.floor(countdown / 60) + ":" + countdown % 60;
    let timedOut = JSON.parse(localStorage.getItem("timedOut"));
    if(countdown==this.appSettings.commonSettings.timeOutTime && timedOut){
       document.getElementById("sessionTimeOut").click();
    }
  });
     this.idle.onTimeout.subscribe(() => {
      document.getElementById("close-modal-btn").click();
      this.idle.stop();
      this.idle.watch();
       if (this.GetSession(this.appSession) != null) {
         this.sessionTimeOut1()
       }
     });
  }

  CardTypeDropdownChange(){
    // debugger
    let ele:any = document.getElementById("cardtypedropdown");
    let cardType = ele.value;
    if(cardType == "credit"){
      this.NavigateToUrl("member/creditcard");
    }
    else if(cardType == "debit"){
      this.NavigateToUrl("member/debitcard");
    }
    else if(cardType == "passport"){
      this.NavigateToUrl("member/passport");
    }
    else if(cardType == "health"){
      this.NavigateToUrl("member/healthcard");
    }
    else if(cardType == "loyalty"){
      this.NavigateToUrl("member/loyaltycard");
    }
    else if(cardType == "driver"){
      this.NavigateToUrl("member/drivinglicense");
    }
    else if(cardType == "sincard"){
      this.NavigateToUrl("member/sincard");
    }
    else if(cardType == "ipDates"){
      this.NavigateToUrl("member/importantDate");
    }
    else if(cardType == "mobilephone"){
      this.NavigateToUrl("member/mobilePhones");
    }
    else if(cardType == "preAuthPay"){
      this.NavigateToUrl("member/preAuthPayment");
    }
    else if(cardType == "safeDepositBox"){
      this.NavigateToUrl("member/safetyDepositBox");
    }
    else if(cardType == "insurancePolicy"){
      this.NavigateToUrl("member/insurancePolicy");
    }
  }

    
  

  reset() {
    this.idle.watch();
  }

  StartTimer() {
    this.idle.watch();
  }

  sessionTimeOut1(){
    localStorage.removeItem(this.appSession);
    localStorage.removeItem("timedOut");
    this._router = this.injector.get(Router);
    this.previceUrl = this._router.url;
    this.sessionTimeOut = true;
    this.NavigateToUrlWithReload("nonmember/home");
}
}

export enum session {
  profileSession = <any>"ProfileSession",
  documentListSession = <any>"documentListSession",
  reimbursmentSession = <any>"reimbursmentSession",
  loginSession=<any>"loginSession",
  provinceSession=<any>"provinceSession",
  contactSession=<any>"contactSession",
  customizedListSession=<any>"customizedListSession"
}

export  enum CardType {
  CREDITCARD = 67,
  DEBITCARD = 68,
  LOYALTYCARD = 89,
  PASSPORT = 65,
  DRIVERSLIC = 76,
  INSPOLICY = 73,
  HEALTHCARD = 72,
  OTHER = 82,
  SINCARD = 78,
  MOBILEPHONE=69,
  PREAUTHPAYMENT=80,
  SAFETYDEPOSITBOX=83
}

export  enum LogLevels {
  INFO = 1,
  DEBUG = 2,
  WARN = 3,
  ERROR = 4,
  FATAL = 5
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor 

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    return "unknown";
}
